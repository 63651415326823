import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes.js'
import store from '../store'


const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	base: '/'
})


// Check if user is authorized
router.beforeEach((to, from, next) => {
	store.dispatch('PageLoader/pageLoader', { loading: true, heading: 'Loading Page...', body: '' });
	store.dispatch('Login/checkLogin')
	// console.log('Coming from path: ', from);
	// console.log('Going to path: ', to);
	if (to.path !== '/' /* && to.path !== '/psw-reset' */ && !store.getters['Login/isAuth'] == true) 
	{
		var toast = {
			shown: false,
			type: 'warning', // ['info', 'warning', 'error', 'okay']
			heading: 'Please login first', // (Optional)
			body: '',
			time: 3000, // in milliseconds
			icon: '' // leave blank for default toast type icon
		}
		store.dispatch('Toast/toast', toast);
		next({ path: '/' });
	}
	/* else */
	if (to.path === '/' && store.getters['Login/isAuth'] == true) {
		var toast = {
			shown: false,
			type: 'okay', // ['info', 'warning', 'error', 'okay']
			heading: 'Already logged in', // (Optional)
			body: '',
			time: 3000, // in milliseconds
			icon: '' // leave blank for default toast type icon
		}
		store.dispatch('Toast/toast', toast);
		next({ path: '/categories_dashboard' });
	}
	else next()
})

router.afterEach((to, from) => {
	store.dispatch('PageLoader/pageLoader', { loading: false, heading: 'Loading Page...', body: '' });
	if (to.path !== '/' /* && to.path !== '/user_psw' */) {
		store.dispatch('Login/checkLogin')
	}
})


// Check if user is authorized
router.afterEach((to, from) => {

	// console.log('From: ', from, 'To: ', to);

	store.dispatch('Menu/resetTitle');

	if (to.meta && to.meta.title)
		store.dispatch('Menu/setTitle', { title: to.meta.title, icon: to.meta.icon ? to.meta.icon : [] })

	// if (to.path !== '/' && to.path !== '/psw-reset') {
	// 	store.dispatch('Login/checkLogin')
		// store.dispatch('QuickMenu/activateMenuItem')

	// }


})

export default router
