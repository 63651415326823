import { axios } from "../../axios/axios";

// initial state
const state = () => ({
    active: false,
    loadingTag: false,
    selectedTag: ''
})





// getters
const getters = {
    active: state => state.active,
    loadingTag: state => state.loadingTag,
    selectedTag: state => state.selectedTag
}





// actions
const actions = {

    active({ commit }, toggle) {
        commit('active', toggle);
    },

    loadingTag({commit}, toggle) {
        commit('loadingTag', toggle);
    },

    selectedTag({commit}, tag) {
        commit('selectedTag', tag);
    },



    saveTagChanges({ state, commit, rootGetters, dispatch }) {

        dispatch('loadingTag', true);
        dispatch('PageLoader/pageLoader', { loading: true, heading: 'Updating Tag', body: '' }, { root: true });

        var toast = {
            shown: false,
            type: 'okay', // ['info', 'warning', 'error', 'okay']
            heading: 'Tag updated successfully', // (Optional)
            body: '', 
            time: 3000, // in milliseconds
            icon: '' // leave blank for default toast type icon
        }


        axios.post('tags/update_tag.php',
            {
                userId: rootGetters['User/user'].id,
                tagId: state.selectedTag.hex_id,
                catId: state.selectedTag.updateCategoryId,
                tagName: state.selectedTag.updateName
            }
        )
        .then(resp => {
            if(resp.data.status)
            {
                commit('updateSelectedTag');
                dispatch('Categories/updateUserCategories', null, { root: true });
                dispatch('Toast/toast', toast, { root: true });
                dispatch('active', false);
            }
            else
            {
                commit('undoUpdateSelectedTag');

                toast.type = 'error';
                toast.heading = 'Server Error';
                toast.body = resp.data.message;
                toast.time = 6000;

                dispatch('Toast/toast', toast, { root: true });

            }
            dispatch('loadingTag', false);
            dispatch('PageLoader/pageLoader', { loading: true, heading: 'Updating Tag', body: '' }, { root: true });
        })
        .catch(err => {
            console.log(err);
            console.log(err.response);

            toast.type = 'error';
            toast.heading = 'Server Error';
            toast.body = err.response;
            toast.time = 6000;

            dispatch('Toast/toast', toast, { root: true });
            dispatch('loadingTag', false);
            dispatch('PageLoader/pageLoader', { loading: true, heading: 'Updating Tag', body: '' }, { root: true });
        })
    },

}





// mutations
const mutations = {

    active(state, toggle) {
        state.active = toggle;
    },

    loadingTag(state, toggle) {
        state.loadingTag = toggle;
    },

    selectedTag(state, tag) {
        state.selectedTag = tag;
    },



    updateSelectedTag(state) {
        state.selectedTag.tag_name = state.selectedTag.updateName;
        state.selectedTag.category_id = state.selectedTag.updateCategoryId;
    },


    undoUpdateSelectedTag(state) {
        state.selectedTag.updateName = state.selectedTag.tag_name;
        state.selectedTag.updateCategoryId = state.selectedTag.category_id;
    }
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}