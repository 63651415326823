<template>
    <div class="dashboard-wrap">

        <SettingsModal :active="settingsActive" @closeSettings="settingsActive = !settingsActive"/>
        <ViewEditTag />
        
        <div class="tags-dashboard-heading-wrap">
            <font-awesome-icon @click="settingsActive = true" class="dashboard-settings-icon icon" :icon="['fa', 'gear']" size="lg" />
            <!-- <h2><font-awesome-icon class="tags-icon" :icon="['fa', 'tags']"  size="lg" /> Tags Dashboard</h2> -->
            <div class="refresh-icon-wrap">
                <font-awesome-icon @click="refreshTags()" class="refresh-tags-icon icon" :class="{ 'disabled' : loadingCategories || loadingTags || updatingTags }" v-if="!loadingCategories && !loadingTags && !updatingTags" :icon="['fa', 'sync-alt']" size="lg" />
                <font-awesome-icon class="refresh-tags-icon icon" :class="{ 'disabled' : loadingCategories || loadingTags || updatingTags }" v-else :icon="['fa', 'sync-alt']" spin size="lg" />
            </div>
        </div>


        <div class="dashboard-heading-wrap">

            <font-awesome-icon @click="loadSelectCategory(false)" class="navigate-category-icon previous icon" :icon="['fa', 'circle-chevron-left']" size="lg" />
            <h3>{{ selectedCategory.category_name }}</h3>
            <font-awesome-icon @click="loadSelectCategory(true)" class="navigate-category-icon next icon" :icon="['fa', 'circle-chevron-right']" size="lg" />
        </div>


        
        <div class="tag-dashboard-scroll-section no-scroll-bar" v-if="selectedCategory && selectedCategory.tagsLoaded && selectedCategory.tags.length >= 1">
            
            <div id="TagCardsGrid" ref="TagCards" class="tag-card-grid" :class="{ 'swipe-left' : swipe.direction == 'left' && swipe.swiping, 'swipe-right' : swipe.direction == 'right' && swipe.swiping }">
                <template v-for="(tag, index) in selectedCategory.tags" :key="index" >
                    <div class="tag-card-wrap">

                        <div class="tag-card-heading-wrap">
                            <h3>{{ tag.tag_name }}</h3>
                            <p v-if="!showHexID">{{ tag.int_id }}</p>
                            <p v-else>{{ tag.hex_id }}</p>
                        </div>

                        <div class="tag-content-wrap">
                            <p>Last Update: </p>
                            <p class="bold">{{ tag.last_updated ? new Date(tag.last_updated).toLocaleString('en-GB').replaceAll('/', '-').replace(',', '') : '--' }}</p>

                            <p>Channel: </p>
                            <p class="bold">{{ tag.channel || '--' }}</p>

                            <p>Attendance: </p>
                            <p class="bold">{{ tag.attendance || '--' }}</p>

                            <p>RSSI: </p>
                            <p class="bold">{{ tag.RSSI || '--' }}</p>
                        </div>

                        <button @click="viewEditTag(tag)" class="edit-tag-btn"><font-awesome-icon class="edit-tag-icon" :icon="['fa', 'pencil']" size="lg" /></button>

                    </div>
                </template>
            </div>
            <div class="scroll-section-bottom-shadow"></div>
        </div>
        <div v-else-if="!loadingCategories && !loadingTags && selectedCategory.tagsLoaded && selectedCategory.tags.length <= 0" class="dashboard-message-wrap">
            <p>No Tags in {{ selectedCategory.category_name }}</p>
        </div>

    </div>
</template>




<script>
import { axios } from '../../axios/axios';
import { mapGetters } from 'vuex';

import SettingsModal from './SettingsModal.vue';
import ViewEditTag from '../Tags/ViewEditTag.vue';

export default {

    components: { SettingsModal, ViewEditTag },

    data() {
        return {

            settingsActive: false,


            compSelectedCategoryId: 0,

            previousCategory: '',
            selectedCategory: '',
            nextCategory: '',

            swipe: {
                swiping: false,
                startX: '',
                endX: '',
                direction: '',
                previousRead: '',
                currentRead: '',
                compoundMovement: 0
            },

            tagCardsElm: '',

            scannerTags: [],
            gettingScannerTags: false,
            refreshInterval: '',
            refreshIntervalTime: 20000,

            showIntegerID: true
            
        }
    },



    computed: {
        ...mapGetters({
            user: ['User/user'],
            loadingCategories: ['Categories/loadingCategories'],
            loadingTags: ['Categories/loadingTags'],
            updatingTags: ['Categories/updatingTags'],
            userCategories: ['Categories/userCategories'],
            selectedCategoryId: ['Categories/selectedCategoryId'],
            showHexID: ['Dashboard/showHex']
        })
    },




    watch: {
        selectedCategoryId: {
            handler: function() {
                console.log('Selected Cat ID:', this.selectedCategoryId, this.userCategories);

                if(this.userCategories && this.selectedCategoryId != '')
                    this.selectedCategory = this.userCategories.find(cat => cat.id == this.selectedCategoryId);
            },
            deep: true
        },


        selectedCategory: function() {
            if(this.selectedCategory)
            {
                var selectedIndex = this.userCategories.findIndex(cat => cat.id == this.selectedCategoryId);

                // End
                if(selectedIndex == this.userCategories.length - 1)
                {
                    this.previousCategory = this.userCategories[selectedIndex - 1];
                    this.nextCategory = this.userCategories[0];
                    // console.log('End')
                }
                // Start
                else if(selectedIndex == 0)
                {
                    this.previousCategory = this.userCategories[this.userCategories.length - 1];
                    this.nextCategory = this.userCategories[selectedIndex + 1];
                    // console.log('Start')
                }
                // Elsewhere
                else
                {
                    // console.log('Elsewhere')
                    this.previousCategory = this.userCategories[selectedIndex - 1];
                    this.nextCategory = this.userCategories[selectedIndex + 1];
                }

                

                // console.log('Prev Cat: ', this.previousCategory);
                console.log('Curr Cat: ', this.selectedCategory);
                // console.log('Next Cat: ', this.nextCategory);
                
            }
        }
    },





    mounted() {
        this.checkUserCategories();

        window.addEventListener('touchstart', this.startSwipe);
        window.addEventListener('touchend', this.endSwipe);

        this.$store.dispatch('Dashboard/setShowHex');
    },


    

    


    beforeDestroy() {
        window.removeEventListener('touchstart', this.startSwipe);
        window.removeEventListener('touchend', this.endSwipe);
    },




    methods: {




        viewEditTag: function(tag) {
            this.$store.dispatch('Tags/selectedTag', tag);
            this.$store.dispatch('Tags/active', true);
        },  




        getScannerTags: function() {

            if(this.gettingScannerTags) { return }

            this.gettingScannerTags = true;

            var userTagIds = [];
            this.userCategories.map(cat => cat.tags.map(tag => userTagIds.push(tag.tag_id)));

            // console.log('User tag ids: ', userTagIds);

            axios.post('tags/get_scanner_tags.php', { userTagIds })
            .then(resp => {
                console.log(resp);
                if(resp.data.status)
                {
                    this.scannerTags = resp.data.data;
                    this.scannerTags.sort((a,b) => new Date(b.date) - new Date(a.date));
                    this.$store.dispatch('Categories/matchTags', this.scannerTags);
                }
                else
                    this.scannerTags = [];
            })
            .catch(err => {
                console.error(err);
                console.error(err.response);
                this.scannerTags = [];
            })

            this.gettingScannerTags = false;

        },





        





        startSwipe: function(e) {
            // e.preventDefault();
            
            this.swipe.swiping = true;
            this.swipe.startX = e.touches[0].clientX;
            this.tagCardsElm = document.getElementById('TagCardsGrid');
            if(this.tagCardsElm)
            {
                this.tagCardsElm.style.position = 'absolute';
                this.tagCardsElm.style.left = 0;
            }
            window.addEventListener('touchmove', this.touchMove);
        },


        touchMove: function(e) {
            // e.preventDefault();
            // console.log('Moving on touch: ', e);
            this.swipe.currentRead = e.changedTouches[0].clientX;
            !this.swipe.previousRead ? this.swipe.previousRead = e.changedTouches[0].clientX : null;
            var movement = this.swipe.currentRead - this.swipe.previousRead;
            this.swipe.compoundMovement += movement;
            this.swipe.previousRead = this.swipe.currentRead;

            // console.log('Start X: ', this.swipe.startX, this.swipe.startX + 30);
            // console.log('total movement: ', this.swipe.compoundMovement);
            if(this.swipe.compoundMovement < 30 && this.swipe.compoundMovement > -30) { return }

            // console.log(Number(movement.toFixed(2)), Number(this.swipe.compoundMovement.toFixed(2))/* ' left: ', this.tagCardsElm.style.left, 'clientX: ', this.tagCardsElm.clientX */);
            this.tagCardsElm ? this.tagCardsElm.style.left = this.tagCardsElm.offsetLeft + movement + 'px' : null;
            // console.log(this.tagCardsElm.style.left);


            
            
        },



        endSwipe: function(e) {
            // e.preventDefault();
            // console.log('Swipe ended: ', e);
            this.swipe.swiping = false;
            this.swipe.endX = e.changedTouches[0].clientX;
            this.swipe.compoundMovement = 0;
            this.tagCardsElm ? this.tagCardsElm.style.left = 0 : null;
            window.removeEventListener('touchmove', this.touchMove);

            // console.log('Swipe: ', this.swipe);

            if(this.swipe.startX > this.swipe.endX)
            {
                if(this.swipe.startX > this.swipe.endX + 80)
                    this.loadSelectCategory(true);
            }
            else   
            {
                if(this.swipe.startX + 80 < this.swipe.endX)
                    this.loadSelectCategory(false);
            }

            this.swipe.startX = '';
            this.swipe.endX = '';
            this.swipe.previousRead = '';
            this.swipe.currentRead = '';
        },











        loadSelectCategory: function(isNext) {

            var selectedIndex = this.userCategories.findIndex(cat => cat.id == this.selectedCategoryId); 
            var beforePreviousCategory = '';
            var afterNextCategory = '';

            // End
            if(selectedIndex == this.userCategories.length - 1)
            {
                this.previousCategory = this.userCategories[selectedIndex - 1]; 
                this.nextCategory = this.userCategories[0];
                beforePreviousCategory = this.userCategories[selectedIndex - 2]; 
                afterNextCategory = this.userCategories[1];
            }
            // Start
            else if(selectedIndex == 0)
            {
                this.previousCategory = this.userCategories[this.userCategories.length - 1];
                this.nextCategory = this.userCategories[selectedIndex + 1];
                beforePreviousCategory = this.userCategories[this.userCategories.length - 2]; 
                afterNextCategory = this.userCategories[selectedIndex + 2];
            }
            // Elsewhere
            else
            {
                // console.log('Elsewhere')
                this.previousCategory = this.userCategories[selectedIndex - 1];
                this.nextCategory = this.userCategories[selectedIndex + 1];
                beforePreviousCategory = selectedIndex == 1 ? this.userCategories[this.userCategories.length - 1] : this.userCategories[selectedIndex - 2]; 
                afterNextCategory = selectedIndex == this.userCategories.length - 2 ? this.userCategories[0] : this.userCategories[selectedIndex + 2];
            }

            
            // console.log('Before previous: ', beforePreviousCategory);
            // console.log('After next: ', afterNextCategory);
            

            if(isNext) 
            {
                this.compSelectedCategoryId = this.nextCategory.id
                this.$store.dispatch('Categories/selectedCategoryId', this.nextCategory.id);
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: this.nextCategory.id, refresh: false });
                this.selectedCategory = this.nextCategory;
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: afterNextCategory.id, refresh: false });
            }
            else
            {
                this.compSelectedCategoryId = this.previousCategory.id
                this.$store.dispatch('Categories/selectedCategoryId', this.previousCategory.id);
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: this.previousCategory.id, refresh: false });
                this.selectedCategory = this.previousCategory;
                this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: beforePreviousCategory.id, refresh: false });
            }

        },






        refreshTags: function() {
            this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: this.selectedCategoryId, refresh: true });
            this.getScannerTags();
        },









        setSelectedCategory: async function() {

            if(this.selectedCategoryId && this.selectedCategory && this.selectedCategory.tagsLoaded) { return }

            this.selectedCategory = '';

            if(this.selectedCategoryId !== 'init')
            {
                this.compSelectedCategoryId = this.selectedCategoryId; 
                this.selectedCategory = this.userCategories.find(cat => cat.id == this.selectedCategoryId);
            }

            if(this.compSelectedCategoryId == 0 && this.selectedCategoryId === 'init')
                this.$store.dispatch('Categories/selectedCategoryId', 0);


            // First start will trigger this if { block }
            if(this.selectedCategory && !this.selectedCategory.tagsLoaded)
            {

                await this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: this.selectedCategoryId, refresh: false });

                this.getScannerTags();

                this.refreshInterval = setInterval(() => {
                    this.getScannerTags();
                }, this.refreshIntervalTime);

                this.userCategories.map(cat => {
                    this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, categoryId: cat.id, refresh: false });
                });
                
            }
        },






        checkUserCategories: async function() {
            if(!this.userCategories || this.userCategories && this.userCategories.length <= 0)   
            {
                await this.$store.dispatch('Categories/getUserCategories', this.user.id);
                this.setSelectedCategory();
            }
            else
                this.setSelectedCategory();
        },
    }
    
}
</script>



<style>


.dashboard-wrap {
    /* padding-top: 70px; */
}


.dashboard-heading-wrap {
    height: 70px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-content: center;
    text-align: center;
}







.navigate-category-icon {
    font-size: 24px;
    color: var(--AppRed);
}

.navigate-category-icon.previous {
    margin-right: 10px;
    justify-self: flex-end;
}

.navigate-category-icon.next {
    margin-left: 10px;
    justify-self: flex-start;
}






.tags-dashboard-heading-wrap {
    position: fixed;
    top: 75px;
    right: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* .tags-dashboard-heading-wrap .tags-icon {
    color: var(--AppRed);
    font-size: 20px;
    margin-right: 8px;
}

.tags-dashboard-heading-wrap h2 {
    display: flex;
    align-items: center;

} */



.dashboard-settings-icon {
    position: fixed;
    top: 82px;
    left: 15px;
    color: rgb(27, 126, 255);
    font-size: 28px;
}



.refresh-icon-wrap {
    background: var(--AppWhite);
    border-radius: 3px;
    padding: 10px;
}

.refresh-tags-icon {
    color: var(--OkGreen); 
}











.tag-dashboard-scroll-section {
    overflow-y: scroll;
    height: calc(100vh - 130px);
    width: calc(100vw + 10px);
    margin: 0 -10px;
    /* box-shadow: inset 0 -8px 16px 0 rgb(0 0 0 / 20%); */
    
    position: relative;
}





.tag-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 320px));
    align-items: center;
    justify-content: center;
    column-gap: 20px;
    
    position: absolute;
    left: 0px;
    width: 100%;
    padding-bottom: 100px;
}


/* @keyframes swipe-cards-left {
    
}


.tag-card-grid.swipe-left {
    left: -200px;
    transition: left 500ms ease-in;
}

@keyframes swipe-cards-right {

}


.tag-card-grid.swipe-right {
    right: -200px;
    transition: right 500ms ease-in;
} */







.tag-card-wrap {
    /*
    min-width: 300px;
    max-width: 80vw;
    border-radius: 3px;
    */

    /* height: 150px; */
    border: none;
    background: var(--BackgroundWhite);
    box-shadow: 3px 4px 12px 0 rgba(0,0,0,0.18);
    margin-bottom: 35px; 
    margin-top: 20px;
    padding-bottom: 15px;
    position: relative;
}

.tag-card-wrap:first-child {
    /* margin-top: 25px; */
}


.edit-tag-btn {
    position: absolute;
    bottom: 0;
    right: 0;

    /* background: rgb(160,160,160); */
    color: var(--TextWhite);
}

.edit-tag-icon {
    color: var(--OkGreen);
}


.tag-card-heading-wrap {
    display: grid;
    grid-template-columns: 4fr 2fr;
    align-items: center;
    background: rgba(181,21,46,0.85);
    color: white;
    border-bottom: 2px solid var(--AppBlack);
}


.tag-card-heading-wrap h3 {
    padding-left: 10px;
    font-size: 18px;
}

.tag-card-heading-wrap p {
    /* margin: -5px 0; */
    justify-self: center;
    text-align: center;
    /* padding: 5px 0; */
    border-left: 2px solid rgb(131, 15, 33);
    width: 100%;
    padding: 7px 0;
    font-size: 14px;
}







.tag-content-wrap {
    display: grid;
    grid-template-columns: 1fr 2fr;

    padding-top: 10px;
    padding-left: 10px;
    font-size: 14px;
}

















.dashboard-message-wrap {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
    
</style>