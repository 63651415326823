<template>
    <div class="tag-dashboard-wrap">
        
        <div class="tags-heading-wrap">
            <!-- <h2><font-awesome-icon class="tags-icon" :icon="['fa', 'rectangle-list']"  size="lg" /> Tag Categories</h2> -->
            <div class="refresh-icon-wrap">
                <font-awesome-icon @click="refreshCategoryTags()" class="refresh-tags-icon icon" :class="{ 'disabled' : editing || loadingCategories || loadingTags || updatingTags }" v-if="!loadingCategories && !loadingTags" :icon="['fa', 'sync-alt']" size="lg" />
                <font-awesome-icon class="refresh-tags-icon icon" :class="{ 'disabled' : editing || loadingCategories || loadingTags || updatingTags }" v-else :icon="['fa', 'sync-alt']" spin size="lg" />
            </div>
        </div>


        <div class="select-category-wrap">
            <!-- <p>Select Category</p> -->
            
            <div class="select-load-category-wrap">
                
                <select :disabled="editing || loadingCategories || loadingTags || updatingTags" v-model="compSelectedCategoryId" @change="$store.dispatch('Categories/selectedCategoryId', compSelectedCategoryId)" placeholder="select category">
                    <option v-for="cat in userCategories" :key="cat.id" :value="cat.id">{{  cat.category_name  }}</option>
                </select>

                <!-- <button :disabled="loadingCategories || loadingTags || editing" @click="loadCategoryTags()"><font-awesome-icon class="load-tags-from-category-icon btn-icon" :icon="['fa', 'tags']"  size="lg" /> Load</button> -->

            </div>
        </div>

        <div class="category-tags-display-wrap">

            <template v-if="selectedCategory && selectedCategory.tags.length <= 0 && !loadingTags && !updatingTags">
                <p class="category-tags-display-message">No Tags in "{{ selectedCategory.category_name }}"</p>
            </template>

            <template v-else-if="selectedCategory">

                <div class="data-grid tags-grid headings">
                    <h4>Tag ID</h4>
                    <h4 class="centered">Tag Description</h4>
                    <h4 class="centered">Category</h4>
                </div> 

                <div class="custom-scroller tags-scroll-section">
                    <div class="scroll-section-loader-wrap" v-if="loadingCategories || loadingTags || updatingTags">
                        <font-awesome-icon class="loading-spinner categories" :icon="['fa', 'spinner']" spin size="lg" />
                        <p>{{ updatingTags ? 'Updating Tags...' : loadingTags ? 'Loading Tags...' : 'Loading Categories...' }}</p>
                    </div>

                    <div class="data-grid tags-grid data" v-for="tag in selectedCategory.tags" :key="tag.id">
                        <div class="data-grid-item-wrap">
                            <p style="font-size: 14px">{{ tag.hex_id }}</p>
                        </div>
                        <div class="data-grid-item-wrap">
                            <p class="tag-category-tag-description">{{ tag.tag_name }}</p>
                        </div>

                        <select v-model="tag.selectedCategoryId" @change="toggleEditing()" :disabled="loadingCategories">
                            <option v-for="cat in userCategories" :key="cat.id" :value="cat.id">{{  cat.category_name  }}</option>
                        </select>
                    </div>
                    <div class="scroll-section-bottom-shadow"></div>
                </div>

            </template>

            <template v-else-if="selectedCategory && !selectedCategory.tagsLoaded && !loadingTags && !updatingTags">
                <p class="category-tags-display-message">Press the "Load" button to load the tags for <br><br><span class="bold app-red">{{ selectedCategory.category_name }}</span></p>
            </template>

            <!-- <template v-else-if="selectedCategory && !selectedCategory.tagsLoaded && loadingTags">
                <div class="loading-tags-wrap">
                    <font-awesome-icon class="loading-spinner tags" :icon="['fa', 'spinner']" spin size="lg" />
                    <p>Loading Tags...</p>
                </div>
            </template> -->

        </div>


        <div class="edit-mode-buttons-wrap" v-if="editing && !loadingCategories && userCategories && !loadingTags && !updatingTags">
            <button class="save-category-changes-btn" @click="saveEdit()"><font-awesome-icon class="btn-icon" :icon="['fa', 'floppy-disk']" size="lg" /> Save Changes</button>
            <button class="cancel-category-changes-btn" @click="cancelEdit()"><font-awesome-icon class="btn-icon" :icon="['fa', 'ban']" size="lg" /> Cancel</button>
        </div>


    </div>
</template>



<script>
import { mapGetters } from 'vuex';
import { axios } from '../../axios/axios';

export default {

    data() {
        return {
            userTags: [],
            compSelectedCategoryId: 0,
            selectedCategory: '',
            editing: false
        }
    },



    computed: {
        ...mapGetters({
            user: ['User/user'],
            userCategories: ['Categories/userCategories'],
            loadingCategories: ['Categories/loadingCategories'],
            loadingTags: ['Categories/loadingTags'],
            updatingTags: ['Categories/updatingTags'],
            selectedCategoryId: ['Categories/selectedCategoryId'],
            selectedCategoryTags: ['Categories/selectedCategoryTags']
        })
    },



    watch: {
        selectedCategoryId: function() {
            
            this.setSelectedCategory();
            
        },

    },



    mounted() {
        this.checkUserCategories();          
    },



    methods: {





        saveEdit: async function() {

            this.editing = false;
            this.$store.dispatch('Categories/updatingTags', true);

            var allOkay = true;

            await Promise.all(this.userCategories.map(async cat => {

                var removeTagIndexes = [];

                await Promise.all(cat.tags.map(async (tag, tagIndex) => {

                    if(tag.category_id !== tag.selectedCategoryId)
                    {

                        console.log('Tag to update: ', tag);
                        await axios.post('tags/update_tag_category.php', { userId: this.user.id, tagId: tag.tag_id, catId: tag.selectedCategoryId })
                        .then(resp => {
                            console.log(resp);
                            if(resp.data.status)
                            {
                                tag.category_id = tag.selectedCategoryId;
                                removeTagIndexes.push(tagIndex);
                                var addToCategory = this.userCategories.find(cat => cat.id == tag.selectedCategoryId);
                                addToCategory.tags.push(tag);
                            }
                            else
                            {
                                tag.selectedCategoryId = tag.category_id;
                                allOkay = false;
                            }
                        }) 
                        .catch(err => {
                            console.error('Connection Error: ', err);
                            console.error('Connection Error Response: ', err.response);
                            var toast = {
                                shown: false,
                                type: 'error', // ['info', 'warning', 'error', 'okay']
                                heading: 'Server Error', // (Optional)
                                body: 'Please try again later', 
                                time: 500, // in milliseconds
                                icon: '' // leave blank for default toast type icon
                            }
                            this.$store.dispatch('Toast/toast', toast);
                        })
                    }

                }))

                cat.tags = cat.tags.filter((tag, tagIndex) => !removeTagIndexes.includes(tagIndex));

                this.$store.dispatch('Categories/setCategoryTags', { tags: cat.tags, categoryId: cat.id });

            }));


            var toast = {
                shown: false,
                type: 'error', // ['info', 'warning', 'error', 'okay']
                heading: 'Server Error', // (Optional)
                body: 'Could not update tags', 
                time: 4000, // in milliseconds
                icon: '' // leave blank for default toast type icon
            }

            if(!allOkay)
                this.$store.dispatch('Toast/toast', toast);
            else
            {
                toast.heading = 'Tag Categories Updated Successfully';
                toast.body = '';
                toast.time = 3000;
                toast.type = 'okay';
                this.$store.dispatch('Toast/toast', toast);
            }

            
            this.$store.dispatch('Categories/updatingTags', false);

        },



        cancelEdit: function() {
            this.userCategories.map(cat => {
                cat.tags.map(tag => tag.selectedCategoryId = tag.category_id);
            });
            this.editing = false;
        },



        toggleEditing: function() {
            this.editing = false;
            this.userCategories.map(cat => {
                cat.tags.map(tag => {
                    if(tag.selectedCategoryId != tag.category_id)
                        this.editing = true;
                })
            })
        },




        loadCategoryTags: function() {

            // this.$store.dispatch('Categories/loadingTags', true);

            if(this.selectedCategoryId === '')
            {
                var toast = {
                    shown: false,
                    type: 'warning', // ['info', 'warning', 'error', 'okay']
                    heading: 'Please select a category', // (Optional)
                    body: '', 
                    time: 3000, // in milliseconds
                    icon: '' // leave blank for default toast type icon
                }

                this.$store.dispatch('Toast/toast', toast);
                return
            }

            this.selectedCategory ? this.selectedCategory.tagsLoaded = false : null;
            this.$store.dispatch('Categories/loadCategoryTags', { userId: this.user.id, refresh: false });

        },





        setSelectedCategory: function() {

            this.selectedCategory = '';

            if(this.selectedCategoryId !== '')
            {
                this.compSelectedCategoryId = this.selectedCategoryId; 
                this.selectedCategory = this.userCategories.find(cat => cat.id == this.selectedCategoryId);
            }

            if(this.compSelectedCategoryId == 0 && this.selectedCategoryId === '')
                this.$store.dispatch('Categories/selectedCategoryId', 0);

            if(!this.selectedCategory.tagsLoaded)
                this.loadCategoryTags();
        },




        checkUserCategories: async function() {
            if(!this.userCategories || this.userCategories && this.userCategories.length <= 0)   
                await this.$store.dispatch('Categories/getUserCategories', this.user.id);

            this.setSelectedCategory();
        },



        refreshCategoryTags: function() {

            this.$store.dispatch('Categories/loadingTags', true);
            this.selectedCategory.tagsLoaded = false;

            axios.post('tags/get_user_tags_by_category.php', { userId: this.user.id, catId: this.selectedCategoryId })
            .then(resp => {

                console.log(resp);

                if(resp.data.status)
                {
                    this.userTags = resp.data.data;
                    this.$store.dispatch('Categories/setCategoryTags', { tags: resp.data.data, categoryId: this.selectedCategoryId });
                }
                else
                {
                    var toast = {
                        shown: false,
                        type: 'error', // ['info', 'warning', 'error', 'okay']
                        heading: 'Server Error', // (Optional)
                        body: 'Could not retrieve tags',
                        time: 5000, // in milliseconds
                        icon: '' // leave blank for default toast type icon
                    }
                    this.$store.dispatch('Toast/toast', toast);
                }
            })
            .catch(err => {
                console.error('Connection Error: ', err);
                console.error('Connection Error Response: ', err.response);
                var toast = {
                    shown: false,
                    type: 'error', // ['info', 'warning', 'error', 'okay']
                    heading: 'Server Error', // (Optional)
                    body: 'Please try again later', 
                    time: 500, // in milliseconds
                    icon: '' // leave blank for default toast type icon
                }
                this.$store.dispatch('Toast/toast', toast);
            })
        },
    }
    
}
</script>



<style>
.tag-dashboard-wrap {
    padding: 0 10px;
    padding-top: 15px;
}


.tags-heading-wrap {
    position: fixed;
    top: 75px;
    right: 10px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 20px; */
}


.tags-icon {
    color: var(--AppRed);
    font-size: 20px;
    margin-right: 8px;
}

.tags-heading-wrap h2 {
    display: flex;
    align-items: center;
}


.refresh-icon-wrap {
    background: var(--AppWhite);
    border-radius: 3px;
    padding: 10px;
}

.refresh-tags-icon {
    color: var(--OkGreen); 
}









.select-category-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    height: 40px;
    position: relative;
}

.select-category-wrap select {
    height: 35px;
    min-width: 200px;
}

.select-category-wrap p {
    position: absolute;
    top: 13px;
    left: 8px;

    color: rgba(15,15,15,0.5);
    font-size: 14px;
    /* color: rgba(15,15,15,0.2); */
}






.select-load-category-wrap {
    display: flex;

}


.select-load-category-wrap button {
    margin-left: 8px;
}


.load-tags-from-category-icon {
    color: var(--OkGreen);

}






.loading-tags-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 20px;
    padding: 18px 10px;
}


.loading-spinner.tags {
    margin-bottom: 8px;
    color: var(--AppRed);
    font-size: 24px;
}







.category-tags-display-wrap {
    /* margin-top: 20px; */
}


.category-tags-display-message {
    text-align: center;
}








.tags-scroll-section {
    overflow-y: scroll;
    width: 100vw;
    margin: 0 -10px;
    /* box-shadow: inset 0 -8px 16px 0 rgb(0 0 0 / 20%); */
    padding-bottom: 100px;
    position: relative;
    height: calc(100vh - 165px);
}




.scroll-section-loader-wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(240,240,240,0.85);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
}



.tags-scroll-section select option {
    width: 100px;
}





.tags-grid {
    grid-template-columns: 2fr 3fr 1fr;
    align-items: center;
    width: 100%;
    padding: 0 10px;
}


.tags-grid.headings {
    background: var(--BackgroundWhite);
    color: var(--TextGrey);
    margin: 0 -10px;
    padding: 0 10px;
    height: 35px;
    padding-right: 17px;
    border-bottom: 1px solid var(--AppRed);
    width: 100vw;
    /* border-top: 1px solid var(--AppRed); */
}


.tags-grid.data select {
    height: 40px;
    border: none;
    border-bottom: 1px solid rgba(40,40,40,0.4);
    background: transparent;
    width: 100%;
}









.edit-mode-buttons-wrap {

    position: fixed;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;

    background: rgba(220,220,220,0.85);
    /* border: 3px solid rgb(250,250,250); */
    border-radius: 3px;

    width: max-content;
    padding: 12px 20px;

    display: flex;
    align-items: center;
    justify-content: center;
}



.save-category-changes-btn {
    background: var(--OkGreen);
    color: var(--TextWhite);
    margin-right: 10px;
}


.cancel-category-changes-btn {
    background: var(--WarningRed);
    color: var(--TextWhite);
    margin-left: 10px;
}


</style>