import axios from 'axios'

console.log('Location hostname: ', window.location.hostname);


if(window.location.hostname != 'mypi.ip-dynamic.org' /* && window.location.hostname != 'localhost' */)
{
    axios.defaults.baseURL = 'http://'+window.location.hostname+'/api';
}
// else if (window.location.hostname == 'localhost')
// {
//     axios.defaults.baseURL = 'http://localhost:8008/cow_count/api';
// }
else
{
    axios.defaults.baseURL = 'https://'+window.location.hostname+'/api';
}


export {
    axios
}