import { axios } from "../../axios/axios"
const crypto = require('crypto');
import router from "../../router/index";

// initial state
const state = () => ({
    isAuth: false,
})





// getters
const getters = {
    isAuth: state => state.isAuth,
}





// actions
const actions = {


    login({state, dispatch, commit}, user) {

        dispatch('PageLoader/pageLoader', { loading: true, heading: 'Logging in...', body: '' }, { root: true });

        const hash = crypto.createHash('sha256').update(user.pw).digest('base64');
        user['password'] = hash;

        axios.post('user/login.php', user)
        .then(resp => {
            // console.log(resp);
            if(resp.data && resp.data.status && resp.data.status == true)
            {

                var user = resp.data.data;
                delete user.password;
                delete user.rfid_serial;
                dispatch('User/setUser', user, { root: true });
                localStorage.setItem('user', JSON.stringify(user));


                commit('setAuth', true);
                var toast = {
                   shown: false,
                   type: 'okay', // ['info', 'warning', 'error', 'okay']
                   heading: 'Login Successful', // (Optional)
                   body: '', 
                   time: 2500, // in milliseconds
                   icon: '' // leave blank for default toast type icon
                }
                dispatch('Toast/toast', toast, { root: true })

                localStorage.setItem('isAuth', true);


                commit('setAuth', true);
                router.push('/categories_dashboard');
            }
            else
            {

                var toast = {
                    shown: false,
                    type: 'error', // ['info', 'warning', 'error', 'okay']
                    heading: 'Username or password incorrect', // (Optional)
                    body: '', 
                    time: 3500, // in milliseconds
                    icon: '' // leave blank for default toast type icon
                }


                if(resp.data.message == 'Username or password incorrect')
                {
                    dispatch('Toast/toast', toast, { root: true })
                }


                else if(resp.data.message == 'Username or password not sent')
                {
                    toast.heading = 'Username or password not sent'; 
                    toast.time = 3500;
                    dispatch('Toast/toast', toast, { root: true })
                }

                else
                {
                    toast.heading = 'Server Error';
                    toast.body = 'Please try again later';
                    toast.time = 5000;
                    dispatch('Toast/toast', toast, { root: true })
                }
                
                localStorage.setItem('isAuth', false);
                commit('setAuth', false);
                
            }
        })
        .catch(err => {
            console.error('Connection Error: ', err);
            console.error('Connection Error Response: ', err.response);
            dispatch('PageLoader/pageLoader', { loading: false, heading: 'Logging in...', body: '' }, { root: true });

            var toast = {
                shown: false,
                type: 'error', // ['info', 'warning', 'error', 'okay']
                heading: 'Server Error', // (Optional)
                body: 'Please try again later', 
                time: 500, // in milliseconds
                icon: '' // leave blank for default toast type icon
             }
             dispatch('Toast/toast', toast, { root: true })
        })

        dispatch('PageLoader/pageLoader', { loading: false, heading: 'Logging in...', body: '' }, { root: true });

    },




    logout({commit, dispatch}) {

        var toast = {
            shown: false,
            type: 'info', // ['info', 'warning', 'error', 'okay']
            heading: 'Logging Out', // (Optional)
            body: '', 
            time: 2500, // in milliseconds
            icon: '' // leave blank for default toast type icon
        }
        dispatch('Toast/toast', toast, { root: true });

        setTimeout(() => {
            localStorage.setItem('isAuth', false);
            commit('setAuth', false);
            router.push('/');
        }, 1500);
        
    },






    checkLogin({ commit, dispatch, rootGetters }) {

        const storageAuth = JSON.parse(localStorage.getItem('isAuth'));
        var user = JSON.parse(localStorage.getItem('user'));
        dispatch('User/setUser', user, { root: true });

        // console.log('Check login running, storageAuth: ', storageAuth);

        if(storageAuth == false || !user)
        {
            commit('setAuth', false);
            // dispatch('logout');
        }
        else
        {
            commit('setAuth', true);
        }
            
    },  

}





// mutations
const mutations = {
    setAuth(state, toggle) {
        state.isAuth = toggle;
    }
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}