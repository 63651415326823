<template>
    <div class="page-modal dashboard-settings-wrap" v-if="active">
        <div class="page-modal-content dashboard-settings-content">
            <!-- <h2>Dashboard Settings</h2> -->

            <div class="toggle-selector-wrap dashboard-settings">
                <h4>Tag ID Type:</h4>
                <div class="selection-toggle-switch dashboard-settings" @click="$store.dispatch('Dashboard/toggleShowHex')"> 
                    <div class="toggle-slider" :class="{ left : showHex, right : !showHex }"></div>
                    <div class="span-wrap">
                        <span :class="{ active : showHex }">Hexadecimal</span>
                    </div>
                    <div class="span-wrap">
                        <span :class="{ active : !showHex }">Integer</span>
                    </div>
                </div>
            </div>


            <button class="page-modal-close-button" @click="$emit('closeSettings')">close</button>
        </div>
    </div>
</template>



<script>
import { mapGetters } from 'vuex';

export default {

    props: ['active'],

    data() {
        return {

        }
    },


    computed: {
        ...mapGetters({
            showHex: ['Dashboard/showHex']
        })
    }

}
</script>



<style>

.dashboard-settings-wrap {
    
}


.dashboard-settings-content {
    width: 80vw;
    max-width: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 10px;
}








.toggle-selector-wrap.dashboard-settings {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    margin-bottom: 30px;
}


.toggle-selector-wrap.dashboard-settings h4 {
    margin-bottom: 10px;
    color: var(--TextWhite);
}



.selection-toggle-switch.dashboard-settings {
    max-width: 220px;
}
    
</style>