<template>
    <div class="page-modal edit-tag-wrap" v-if="active">
        
        <div class="page-modal-content edit-tag">

            <h2><font-awesome-icon class="edit-tag-heading-icon icon" :icon="['fa', 'pencil']" size="lg" /> Edit Tag</h2>

            <!-- {{ selectedTag }} -->

            <div class="edit-tag-inputs-wrap">
                <h4>Name</h4>
                <input type="text" v-model="selectedTag.updateName">

                <h4 class="align-center">Category</h4>
                <select class="align-center" v-model="selectedTag.updateCategoryId">
                    <option v-for="cat in userCategories" :key="cat.id" :value="cat.id">{{  cat.category_name  }}</option>
                </select>
            </div>


            <div class="edit-tag-data-wrap">
                <h4>Hex ID</h4>
                <p>{{ selectedTag.hex_id }}</p>
                <h4>Integer ID</h4>
                <p>{{ selectedTag.int_id }}</p>
                <h4>Last Update</h4>
                <p>{{ selectedTag.last_updated ? new Date(selectedTag.last_updated).toLocaleString('en-GB').replaceAll('/', '-').replace(',', '') : '--'  }}</p>
                <h4>Scanner ID</h4>
                <p>{{ selectedTag.lastScannerId }}</p>
            </div>



            <div class="edit-tag-btn-wrap">
                <button @click="saveChanges()" class="edit-tag-save-button"><font-awesome-icon class="btn-icon" :icon="['fa', 'floppy-disk']" size="lg" /> Save</button>
                <button @click="cancelChanges()" class="edit-tag-close-button"><font-awesome-icon class="btn-icon" :icon="['fa', 'ban']" size="lg" /> Cancel</button>
            </div>

        </div>

    </div>
</template>



<script>
import { mapGetters } from 'vuex';


export default {

    
    data() {
        return {

        }
    },


    watch: {
        active: function() {
            this.selectedTag.updateCategoryId = Number(this.selectedTag.updateCategoryId);
            console.log(this.selectedTag);
        },

        loadingTag: function() {
            if(this.loadingTag)
                this.$store.dispatch('PageLoader/pageLoader', { loading: true, heading: 'Updating Tag', body: '' });
            else
            this.$store.dispatch('PageLoader/pageLoader', { loading: false, heading: 'Updating Tag', body: '' });
        },
    },


    computed: {
        ...mapGetters({
            active: ['Tags/active'],
            selectedTag: ['Tags/selectedTag'],
            userCategories: ['Categories/userCategories'],
            loadingTag: ['Tags/loadingTag']
        })
    },




    mounted() {
        
    },





    methods: {


        saveChanges: function() {
            if(this.selectedTag.tag_name != this.selectedTag.updateName || this.selectedTag.category_id != this.selectedTag.updateCategoryId)
                this.$store.dispatch('Tags/saveTagChanges');
            else
                this.cancelChanges();
        },




        cancelChanges: function() {
            this.selectedTag.updateName = this.selectedTag.tag_name;
            this.selectedTag.updateCategoryId = this.selectedTag.category_id;
            this.$store.dispatch('Tags/active', false);
        }

    }

}
</script>



<style>

.edit-tag-wrap {
    padding: 10px;
}



.page-modal-content.edit-tag {
    width: 90vw;
    max-width: 600px;
    height: 50%;
    max-height: 800px;
    background: rgba(180,180,180,0.2);
    color: var(--TextBlack);
    padding: 10px 20px;
}


.page-modal-content.edit-tag h2 {
    margin-bottom: 20px;
    text-align: center;
    color: var(--TextBlack);
}


.edit-tag-heading-icon {
    font-size: 20px;
    color: var(--AppRed);
}




.edit-tag-inputs-wrap {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    align-items: flex-end;
    row-gap: 10px;
    color: var(--TextBlack);
    margin-bottom: 20px;
}


.edit-tag-inputs-wrap .align-center {
    align-self: center;
}


.edit-tag-inputs-wrap input {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--TextBlack);
    color: var(--TextBlack);
}


.edit-tag-inputs-wrap select {
    height: 35px;
    background: rgba(210,210,210,0.8);
    border: none;
    color: var(--TextBlack);
    padding-left: 5px;
}







.edit-tag-data-wrap {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    align-items: center;
    row-gap: 10px;
    color: var(--TextBlack);
    /* margin-bottom: 20px; */
}

.edit-tag-data-wrap p {
    padding-left: 5px;
}











.edit-tag-btn-wrap {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
}




.edit-tag-save-button {
    color: var(--TextWhite);
    margin-right: 10px;
    background: var(--OkGreen);
}
 
.edit-tag-close-button {
    color: var(--TextWhite);
    margin-left: 10px;
    background: var(--WarningRed);
}

.edit-tag-save-button .btn-icon,
.edit-tag-close-button .btn-icon {
    color: var(--TextWhite);
}
</style>