// initial state
const state = () => ({
    showHex: false,
})





// getters
const getters = {
    showHex: state => state.showHex
}





// actions
const actions = {
    toggleShowHex({ commit }) {
        commit('toggleShowHex');
    },

    setShowHex({ commit }) {
        commit('setShowHex');
    },
}





// mutations
const mutations = {
    toggleShowHex(state) {
        state.showHex = !state.showHex;
        localStorage.setItem('showHex', state.showHex);
    },


    setShowHex(state) {
        var showing = JSON.parse(localStorage.getItem('showHex'));
        if(showing != undefined)
        {
            state.showHex = !state.showHex;
            state.showHex = showing;
        }

        
    }
}





export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}